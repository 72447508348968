.object-editor-key-section {
  display: flex;
  flex-flow: row;
  margin: -1em;
}

.object-editor-key-section > * {
  margin: 1em;
}

.object-editor-key-section .error {
  color: red;
}

.object-editor-key-section .form-control.error {
  border-color: red;
}

.object-editor-key-fields {
  flex: 1 1 auto;
}

.object-editor-value-section {
  display: flex;
  flex-flow: row;
  margin: -1em;
}

.object-editor-value-section > * {
  margin: 1em;
}

.object-editor-button-container {
  flex: 0 0 auto;
}
