body {
    background-color: #eee;
}

.footer {
    margin-top: 20px;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
}

.footer hr {
    border-color: #999;
    margin-bottom: 0px;
}
.form-container {
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-color: #337ab7;
    border-radius: 10px;
    padding: 5px 25px 15px 25px;
    margin: 10px 30px 20px 14px;
}

.form-container .btn-submit {
    max-width: 200px;
    float: right;
}


/* RADIOS & CHECKBOXES STYLES */

/* base styles */
input[type="radio"],
input[type="checkbox"] {
    height: 1.2em;
    width: 1.2em;
    min-width: 15px;
    vertical-align: middle;
    margin: 0 0.4em 0.4em 0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: -webkit-linear-gradient(#FCFCFC, #DADADA);
    -webkit-appearance: none;
    -webkit-transition: box-shadow 200ms;
     box-shadow:inset 1px 1px 0 #fff, 0 1px 1px rgba(0,0,0,0.1);
}

/* border radius for radio*/
input[type="radio"] {
    -webkit-border-radius:100%;
    border-radius:100%;
}

/* border radius for checkbox */
input[type="checkbox"] {
    -webkit-border-radius:2px;
    border-radius:2px;
}

/* hover state */
input[type="radio"]:not(:disabled):hover,
input[type="checkbox"]:not(:disabled):hover {
    border-color:rgba(0,0,0,0.5);
    box-shadow:inset 1px 1px 0 #fff, 0 0 4px rgba(0,0,0,0.3);
}

/* active state */
input[type="radio"]:active:not(:disabled),
input[type="checkbox"]:active:not(:disabled) {
    background-image: -webkit-linear-gradient(#C2C2C2, #EFEFEF);
    box-shadow:inset 1px 1px 0 rgba(0,0,0,0.2), inset -1px -1px 0 rgba(255,255,255,0.6);
    border-color:rgba(0,0,0,0.5);
}

/* focus state */
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline:none;
    box-shadow: 0 0 1px 2px rgba(0, 240, 255, 0.4);
}

/* input checked border color */
input[type="radio"]:checked,
input[type="checkbox"]:checked {
    border-color:rgba(0, 0, 0, 0.5)
}

/* radio checked */
input[type="radio"]:checked:before {
display: block;
height: 0.3em;
width: 0.3em;
position: relative;
left: 0.4em;
top: 0.4em;
background: rgba(0, 0, 0, 0.7);
border-radius: 100%;
content: '';
}

/* checkbox checked */
input[type="checkbox"]:checked:before {
font-weight: bold;
color: rgba(0, 0, 0, 0.7);
content: '\2713';
-webkit-margin-start: 0;
margin-left: 2px;
font-size: 0.9em;
}
