/* generateapikey.css */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 20px;
  border: 1px solid black;;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 42%);
}

.popup-content {
  text-align: center;
}

.close-btn {
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #d32f2f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #d32f2f; /* Darker red color on hover */
}

/* Prevent scrolling when the popup is active */
body.popup-open {
  overflow: hidden;
}