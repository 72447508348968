.saving-caption {
  clear: both;
  font-style: italic;
}

.platform-filter {
  height: auto;
  max-height: 142px;
  list-style: none;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: scroll;
}

.platform-filter li {
  height: 20px;
}

.platform-filter li label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pam-help-text {
  display: flex;
  align-items: baseline;
  margin-top: -16px;
}

.pam-help-text-toggle {
  cursor: help;
  margin-right: 6px;
}

.pam-help-text-toggle:hover {
  opacity: 0.7;
}
