.click-to-edit-platform {
  cursor: pointer;
}

.platform-item {
    margin: 12px;
}

.platform-item:nth-child(even) {
    background: #dfe3ee;
}

.platform-item table {
    Background: inherit;
}

.is-editing-platform {
  background-color: #ccc !important;
}
