.threatminder-post .table {
  table-layout: fixed;
}

.threatminder-post .x-detected-th {
  width: 4em;
}

.threatminder-post .threat-th {
  width: 2em;
}

.threatminder-post input[type=number] {
  min-width: 74px;
  max-width: 6em;
}

.threatminder-post .form-group {
  display: inline-block;
  margin-right: 10px;
}

.threatminder-post label {
  margin-right: 10px;
}

.threatminder-post .post-body {
  position: relative;
  max-height: 100px;
  overflow: hidden;
}

.threatminder-post .post-body.expanded {
  max-height: none;
  cursor: pointer;
}

.threatminder-post .post-body.clipped {
  cursor: pointer;
}

.threatminder-post .expand-curtain {
  visibility: hidden;
  position: absolute;
  width: 100%;
  background: linear-gradient(to top, white, gray);
  opacity: 0.9;
  bottom: 0;
  text-align: center;
}

.threatminder-post .post-body.clipped .expand-curtain {
  visibility: visible;
}

.threatminder-post .threat-word-in-post {
  background-color: #e98aa2;
  padding: 1px 0;
}

.threatminder-post .action-name-in-post {
  background-color: rgb(255, 182, 46);
  padding: 1px 0;
}

.threatminder-post .action-match-row-addon {
  /* This is a hack.
  TODO refactor /review-posts page to use flexbox instead of
  tables */
  width: 800%;
}

.post-source-url {
  word-wrap: break-word;
}

.paginator ul {
    list-style-type: none;
    margin: 0;
    padding: 1px;
    overflow: hidden;
    background-color: #333333;
    border: 1px black;
}

.paginator li {
    float: left;
}

.paginator li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

.paginator li .end {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

.paginator li.current {
    background: #eee;
}

.paginator li.current a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
    color: black;
}

#filterByType option.default {
    font-weight: bold;
}

.posts-container .is-empty-result-set {
    text-align: center;
}

.no-results {
    display: none;
}
