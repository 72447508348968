.note {
  white-space: pre-line;
}

[data-tip=true] {
  cursor: default;
}

.threat-detected-value {
  cursor: help;
}
