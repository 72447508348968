.click-to-edit-email-campaign {
  cursor: pointer;
}

.is-editing-email-campaign {
  background-color: #ccc;
}

.email-campaign-save-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0;
}
