.click-to-edit-action {
  cursor: pointer;
}

.is-editing-action {
  background-color: #ccc;
}

.action-options {
  white-space: pre-wrap;
}

.data-pull-button {
  margin: 20px 0 10px 0;
  vertical-align: baseline;
}

.data-pull-status-message {
  font-style: italic;
}

.data-pull-status-message:before {
  content: " ";
}
