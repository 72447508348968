.click-to-edit-filter {
  cursor: pointer;
}

.is-editing-filter {
  background-color: #ccc;
}

.filter-options {
  white-space: pre-wrap;
}
