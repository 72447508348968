.dropdown{
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    float: right;
}

.dropdown select{
    color: black !important;
}