.click-to-edit-user {
  cursor: pointer;
}
.user-api-key{
  display: flex;
  flex-direction: column ;
}
.user-item {
  margin: 12px;
}

.user-item:nth-child(even) {
  background: #dfe3ee;
}

.user-item table {
  background: inherit;
}

.is-editing-user {
  background-color: #ccc !important;
}

.user-details {
  table-layout: fixed;
}

.user-details td {
  overflow-wrap: break-word;
}

.user-required {
  color: #a94442;
}

.user-email-campaign-management {
  margin-top: 20px;
}

.user-email-campaigns {
  margin-right: 0;
  margin-left: 0;
}

.select-email-campaigns-container {
  margin-top: 20px
}

.badge-user-unsubscribed {
  background: #aaa;
}

.campaign-start-end {
  font-size: 10px;
}

.generate-btn-cls{
  display: flex;
  align-items: flex-start;
  margin-left: 0.5rem;
}

.update-api-btn-cls{
  display: flex;
  flex-direction: row;
}
