.dropzone > div {
    width: 100% !important;
    height: 250px !important;
    border-width: 5px !important;
    border-color: rgb(102, 102, 102) !important;
    border-style: dashed !important;
    border-radius: 8px !important;
    background-color: rgba(102, 102, 102, 0.15) !important;
    padding: 25px !important;
    cursor: pointer !important;
}

.dropzone.finished > div {
    cursor: default !important;
    border-style: solid !important;
    background-color: rgba(201, 201, 201, .5) !important;
    opacity: 1 !important;
    height: 100% !important;
}

.dropzone hr {
    border-width: 3px;
    border-color: rgb(102, 102, 102);

}
.dropzone.finished .upload-instructions {
    display: none !important;
}

.dropzone .api-results-container .spinner-container {
    text-align: center;
}

.dropzone .api-results-container .spinner-container i {
    color: rgb(102, 102, 102) !important;
    font-size: 34px;
}

.dropzone .error {
    color: red;
}

.dropzone pre.json {
    white-space: pre-wrap;
}
