.companies-container .form-container > * {
    margin-top: 10px;
}

.companies-container .form-container > form {
    margin-top: 15px;
}

.companies-container .form-container .btn-submit {
    margin-top: 15px;
}

.companies-container .list-group {
    margin: 0 15px;
}

.companies-container .list-group-item-title {
    font-size: 20px;
    line-height: 1.5;
}

.companies-container .company-note {
    white-space: pre;
}

.companies-container .company-note-note {
    padding-left: 13px;
}

.companies-container .form-control-note {
    font-size: 12px;
    color: #999;
}
